import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api'

import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import Spinner from '../../components/Spinner';
import { getToken } from '../../services/auth';


function CustomerProfileSimpleForm() {
    let history = useHistory();
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userPasswordConfirmation, setUserPasswordConfirmation] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [emailsLimit, setEmailsLimit] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        const token = getToken();
        const formData = new FormData();

        formData.append('logo', companyLogo);
        formData.append('user[name]', userName);
        formData.append('user[email]', userEmail);
        formData.append('user[password]', userPassword);
        formData.append('user[password_confirmation]', userPasswordConfirmation);
        formData.append('name', companyName);
        formData.append('sender_email', senderEmail);
        formData.append('emails_limit', emailsLimit);

        await api.post('customers', formData, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'success',
                layout: 'topRight',
                text: 'Cliente cadastrado com sucesso!'
            }).show();
            window.location.reload();

        }).catch(function(error) {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'error',
                layout: 'topRight',
                text: error.response.data.message
            }).show();
        });
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="text-left">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Usuário</h4>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="user_name">Nome do usuário</label>
                            <input type="text"
                                name="name"
                                id="user_name"
                                placeholder="Nome"
                                required
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="user_email">E-mail do usuário</label>
                            <input type="text"
                                name="email"
                                id="user_email"
                                placeholder="E-mail"
                                required
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="user_password">Senha do usuário</label>
                            <input type="password"
                                name="password"
                                id="user_password"
                                placeholder="Senha"
                                required
                                value={userPassword}
                                onChange={(e) => setUserPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="user_password_confirm">Confirme a senha do usuário</label>
                            <input type="password"
                                name="password"
                                id="user_password_confirm"
                                placeholder="Confirmação da senha"
                                required
                                value={userPasswordConfirmation}
                                onChange={(e) => setUserPasswordConfirmation(e.target.value)} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <h4>Empresa</h4>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="company_logo">Logo da empresa</label>
                            <input type="file"
                                name="logo"
                                id="company_logo"
                                placeholder="Logo"
                                onChange={(e) => setCompanyLogo(e.target.files[0])} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="company_name">Nome da empresa</label>
                            <input type="text"
                                name="name"
                                id="company_name"
                                placeholder="Nome"
                                required
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="sender_email">Em qual e-mail você deseja receber as respostas das pesquisas?</label>
                            <input type="text"
                                name="email"
                                id="sender_email"
                                placeholder="E-mail"
                                required
                                value={senderEmail}
                                onChange={(e) => setSenderEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="sender_email">Número de disparos por ciclo</label>
                            <input type="text"
                                name="emails_limit"
                                id="emails_limit"
                                placeholder="Disparos por ciclo"
                                required
                                value={emailsLimit}
                                onChange={(e) => setEmailsLimit(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className="submit-form mt-4">
                    <button type="submit">Finalizar</button>
                </div>
            </form>
            {loading && <Spinner />}
        </>
    );
}

export default CustomerProfileSimpleForm;
