import React, { useState, useContext, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Sidenav from '../../components/Sidenav';
import Spinner from '../../components/Spinner';
import api from '../../services/api'

import './style.css'
function SendAnswerEmail({ action, npsId }) {
    const [actionSidenav, setActionSidenav] = useContext(action);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        setLoading(true);
        e.preventDefault();
        await api.post(`nps/${npsId}/send-answer`, {
            subject,
            body
        }).then(response => {
            setLoading(false);
            document.getElementById('send-answer-email').remove();
            window.showNotification('success', 'Resposta enviada com sucesso!');
        }).catch(error => {
            setLoading(false);
            window.showNotification('error', 'Erro ao enviar resposta.');
        });
        setActionSidenav(false);
    }

    return (
        <>
            <Sidenav title="Enviar resposta" action={action} width="50%">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block d-flex flex-column">

                                <label htmlFor="subject">Assunto</label>
                                <input type="text"
                                    name="subject"
                                    id="subject"
                                    placeholder="Assunto"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-block d-flex flex-column">
                                <label htmlFor="body">Corpo do email</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={body}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setBody(data);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="submit-form">
                        <button type="submit">Finalizar</button>
                    </div>
                </form>
            </Sidenav>
            {loading && <Spinner />}
        </>
    );
}

export default SendAnswerEmail;