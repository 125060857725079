import React, { useState, useEffect, createContext } from "react";
import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import 'react-select2-wrapper/css/select2.css';

import Spinner from '../../components/Spinner';
import PageTop from "../../components/PageTop";
import PollSimpleForm from "../../parts/PollSimpleForm";

import api from '../../services/api';

export default function Poll() {

    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const clientsList = [];
    const [message, setMessage] = useState('');
    const [mainQuestion, setMainQuestion] = useState('');
    const [questions, setQuestions] = useState([]);
    const [generatedLink, setGeneratedLink] = useState("");
    const [showGeneratedLink, setShowGeneratedLink] = useState(false);
    const [clientEmails, setClientEmails] = useState({});

    const buttons = [
        {
            text: "Gerar pesquisa para WhatsApp",
            style: "black",
            action: generateNpsLink,
        },
        {
            text: 'Enviar Pesquisa',
            style: 'green',
            icon: "send",
            action: sendNps,
        },
    ]

    useEffect(() => {
        setLoading(true);
        async function loadClients() {
            await api.get(`client/all/0`, {
                params: {
                    is_deleted: 0
                }
            })
            .then(response => {
                setLoading(false);
                response.data.data.map((data, key) => {
                    var clientShape = {
                        label: data.name,
                        options: 
                            data.email.map((email, key) => (
                                {
                                    label: email,
                                    value: email,
                                    clientId: data.id
                                }
                            ))
                        
                    }
                    clientsList.push(clientShape);
                })
                setClients([...clientsList]);
            })
            .catch(error => {
                setLoading(false);
                new Noty({
                    theme: 'mint',
                    timeout: 10000,
                    progressBar: true,
                    closeWith: ['click', 'button'],
                    type: 'error',
                    layout: 'topRight',
                    text: 'Ocorreu um erro ao carregar os clientes!'
                }).show();
            });
        }
        loadClients();
    }, []);

    async function generateNpsLink(event) {
        setLoading(true);
        await api.post(`nps/send-by-whatsapp`, {
            emails: clientEmails,
            message,
            main_question: mainQuestion,
            questions,
        })
        .then(async (response) => {
            setLoading(false);
            setGeneratedLink(response.data.data);
            setShowGeneratedLink(true);
            await navigator.clipboard.writeText(response.data.data);
            new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "success",
            layout: "topRight",
            text: "Link gerado e copiado!",
            }).show();
        })
        .catch((e) => {
            setLoading(false);
            var text = e.response.data ? e.response.data : 'Ocorreu um erro ao gerar o link!';
            new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "error",
            layout: "topRight",
            text: text,
            }).show();
        });

        setTimeout(() => {
        setShowGeneratedLink(false);
        setGeneratedLink("");
        }, 10000);
    }

    async function sendNps() {
        setLoading(true);
        await api.post('nps/store', {
            emails: clientEmails,
            message,
            main_question: mainQuestion,
            questions,
        }).then(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'success',
                layout: 'topRight',
                text: 'NPS enviado com sucesso!'
            }).show();
        }).catch(e => {
            setLoading(false);
            var text = e.response.data ? e.response.data : 'Ocorreu um erro ao enviar o nps!';
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'error',
                layout: 'topRight',
                text: text
            }).show();
        })
    }

    return(
        <>
            <PageTop title={'Pesquisas'} navigation={false} buttons={buttons} >
                
            </PageTop>

            <PollSimpleForm
                clients={clients}
                message={message}
                setMessage={setMessage}
                mainQuestion={mainQuestion}
                setMainQuestion={setMainQuestion}
                questions={questions}
                setQuestions={setQuestions}
                clientEmails={clientEmails}
                setClientEmails={setClientEmails}
            />

            {showGeneratedLink 
            &&
            <div id="bottom-noty">
                <span id="generated-link">{generatedLink}</span>
            </div>
            }
            
            {loading && <Spinner />}
        </>
    );
}