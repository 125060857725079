import React from 'react';
import { useHistory } from "react-router-dom";

import './main.css'

function PageTop({ comeBackUrl, children, title, navigation = false, navigationItens, activeTab, setActiveTab, comeBack = false, additionalInfoElement, buttons, complementaryInfo }) {
    let history = useHistory();

    return (
        <header id="page-top" className={`mb-5 row ${navigation ? 'with-navigation' : ''}`}>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                        {
                            (comeBack)
                                ?
                                <div className="cx-blue cursor-pointer come-back-block mr-3">
                                    <span className="material-icons" onClick={() => history.push(comeBackUrl)}>arrow_back</span>
                                </div>
                                :
                                null
                        }
                        <div className="page-top-title mr-5">
                            <h1 className="mb-0">{title}</h1>
                        </div>
                        <div>
                            {
                                additionalInfoElement
                                    ?

                                    { ...additionalInfoElement }
                                    :
                                    null
                            }
                        </div>
                        <div>
                            
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <h3 className="mb-0">{complementaryInfo}</h3>
                        {
                            buttons
                                ?
                                buttons.map((button, key) => (
                                    <button key={key} type="button" className={`top-btn top-btn-${button.style} ${button.conditional_class}`} onClick={button.action}>
                                        {
                                            button.icon
                                                ?
                                                <span className="material-icons">{button.icon}</span>
                                                :
                                                null
                                        }
                                        {button.text}
                                    </button>
                                ))
                                :
                                null
                        }
                    </div>
                </div>
                {
                    (navigation)
                        ?
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <ul className="list-group list-group-horizontal list-style-none">
                                    {
                                        navigationItens.map((item, key) => (
                                            <li key={key} className={activeTab === (item.id ?? key) ? 'navigation-item-active' : ''} onClick={() => setActiveTab(item.id ?? key)}>{item.name}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        :
                        null
                }

            </div>
            {children}
        </header>
    );
}

export default PageTop;