import React, { useState, useEffect, createContext } from "react";
import {useHistory} from "react-router-dom";

import PageTop from '../../components/PageTop';
import CustomDataTable from '../../components/CustomDataTable';
import FloatButton from '../../components/FloatButton';
import Sidenav from '../../components/Sidenav';
import Spinner from '../../components/Spinner';
import CustomerProfileSimpleForm from "../../parts/CustomerProfileSimpleForm";

import api from '../../services/api';

export default function CustomersProfile() {

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [actionSidenav, setActionSidenav] = useState(0);
    const [clients, setClients] = useState([]);
    const contextActionSidenav = createContext([actionSidenav, setActionSidenav]);

    useEffect(() => {
        setLoading(true);
        loadClients();
    }, []);

    async function loadClients() {
        await api.get('customers')
        .then(response => {
            setLoading(false);
            setClients(response.data);
        })
        .catch(error => {
            setLoading(false);
            window.showNotification('error', 'Erro ao carregar clientes.');
        })
    }

    const dataTableColumns = [
        {
            name: 'Empresa',
            selector: 'name',
            sortable: true,
            filterable: true
        },
        {
            name: 'Disparador',
            selector: 'sender_email',
            sortable: true,
            filterable: true,
            cell: row => <span>{row.sender_email}</span>
        },
    ];

    function sidenavAction() {
        setActionSidenav(!actionSidenav);
    }

    function rowClicked(rowData) {
        history.replace({
            pathname: `/empresas/${rowData.id}`,
            state: {clientName: rowData.name, score: rowData.score}
        })
    }

    return (
        <>
            <PageTop title={'Empresas'} navigation={false}>
                <FloatButton location={'top'} action={sidenavAction}/>
            </PageTop>
            
            <CustomDataTable selectableRows={false} data={clients} columns={dataTableColumns} rowClicked={rowClicked}/>

            <Sidenav title='Empresas' action={contextActionSidenav}>
                <div id="client-form" className="tab-pane fade show active" role="tabpanel" aria-labelledby="create-tab">
                    <CustomerProfileSimpleForm />
                </div>
            </Sidenav>
            
            {loading && <Spinner/>}
        </>
    );
}