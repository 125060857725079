import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from "./services/auth";

import Dashboard from './pages/Dashboard';
import Client from './pages/Client';
import ClientShow from './pages/Client/show';
import ClientTrash from './pages/Client/trash';
import Login from './pages/Login'
import Service from './pages/Service';
import Profile from './pages/Profile';
import CustomersProfile from './pages/CustomersProfile';
import CustomersProfileShow from './pages/CustomersProfile/show';
import Poll from './pages/Poll';
import Applications from './parts/Applications';
import Menu from './parts/Menu';
import Head from './parts/Head';
import ClientNew from './pages/ClientNew';

import Noty from 'noty'

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import ServiceShow from './pages/Service/show';

window.showNotification = (type, text) => {
    new Noty({
        theme: 'mint',
        timeout: 10000,
        progressBar: true,
        closeWith: ['click', 'button'],
        type,
        layout: 'topRight',
        text
    }).show();
}

window.bodyOverlay = () => {
    var overlayEl = document.getElementsByClassName('overlay');
    if (overlayEl.length) {
        overlayEl[0].remove();
    } else {
        var bodyEl = document.getElementsByTagName("BODY")[0];
        overlayEl = document.createElement('div');
        overlayEl.classList.add('overlay');
        bodyEl.appendChild(overlayEl);
    }
}

const PrivateRoute = ({ children, ...rest }) => (
    <Route
        {...rest}
        render={({ location }) =>
            isAuthenticated() ? (
                <>
                    <div id="left-nav-group">
                        <Menu />
                    </div>
                    <div id="content-container">
                        <div className="container-fluid">
                            <div id="page-content">
                                {children}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: location } }} />
            )
        }
    />
);

export default function Routes() {
    return (
        <Router basename={'/administrativo'}>
            <Route path="/login">
                <Login />
            </Route>
            <Switch>

                <PrivateRoute path="/" exact>
                    <Head title="Dashboard">
                        <Dashboard />
                    </Head>
                </PrivateRoute>
                {/*<PrivateRoute exact path="/clientes">*/}
                {/*    <Head title="Clientes">*/}
                {/*        <ClientNew />*/}
                {/*    </Head>*/}
                {/*</PrivateRoute>*/}
                <PrivateRoute exact path="/clientes">
                    <Head title="Clientes">
                        <Client />
                    </Head>
                </PrivateRoute>
                <PrivateRoute path="/clientes/:id" >
                    <ClientShow />
                </PrivateRoute>
                <PrivateRoute exact path="/servicos">
                    <Head title="Serviços">
                        <Service />
                    </Head>
                </PrivateRoute>
                <PrivateRoute path="/servico/:id" >
                    <ServiceShow />
                </PrivateRoute>
                <PrivateRoute path="/perfil">
                    <Head title="Perfil">
                        <Profile />
                    </Head>
                </PrivateRoute>
                <PrivateRoute exact path="/empresas">
                    <Head title="Empresas">
                        <CustomersProfile />
                    </Head>
                </PrivateRoute>
                <PrivateRoute path="/empresas/:id">
                    <CustomersProfileShow />
                </PrivateRoute>
                <PrivateRoute exact path="/pesquisas">
                    <Head title="Pesquisas">
                        <Poll />
                    </Head>
                </PrivateRoute>
                <PrivateRoute exact path="/lixeira">
                    <Head title="Lixeira">
                        <ClientTrash />
                    </Head>
                </PrivateRoute>
            </Switch>
        </Router>
    )
}