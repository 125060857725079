import React, { useState, useContext } from "react";
import CreatableSelect from "react-select/creatable";

import api from "../../services/api";

import Noty from "noty";

import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import Sidenav from "../../components/Sidenav";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom";

function ClientSimpleForm() {
  let history = useHistory();
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState([]);
  const [clientPhoneInputValue, setClientPhoneInputValue] = useState("");
  const [creatablePhoneSelectValue, setCreatablePhoneSelectValue] = useState(
    []
  );
  const [creatableEmailSelectValue, setCreatableEmailSelectValue] = useState(
    []
  );
  const [clientEmail, setClientEmail] = useState([]);
  const [clientEmailInputValue, setClientEmailInputValue] = useState("");
  const [clientResponsibleName, setClientResponsibleName] = useState("");
  const [clientSendingDay, setClientSendingDay] = useState("");
  const [clientObservation, setClientObservation] = useState("");
  const [clientTest, setClientTest] = useState(false);
  const [loading, setLoading] = useState(false);

  function createOption(label) {
    return {
      label,
      value: label,
    };
  }

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    await api
      .post(`client/store`, {
        name: clientName,
        email: clientEmail,
        phone: clientPhone,
        responsible_name: clientResponsibleName,
        sending_day: clientSendingDay,
        observation: clientObservation,
        test_client: clientTest,
      })
      .then((response) => {
        setLoading(false);
        new Noty({
          theme: "mint",
          timeout: 10000,
          progressBar: true,
          closeWith: ["click", "button"],
          type: "success",
          layout: "topRight",
          text: "Cliente cadastrado com sucesso!",
        }).show();
        history.replace({
          pathname: `/clientes/${response.data.client.id}`,
          state: { clientName: response.data.client.name, average: "-" },
        });
      })
      .catch((error) => {
        setLoading(false);
        window.showNotification("error", "Erro ao cadastrar cliente.");
      });
  }

  function handleChangeCreatableEmailSelect(value, actionMeta) {
    setCreatableEmailSelectValue(value);
    setClientEmail(value.map((value) => value.value));
  }

  function handleInputChangeCreatableEmailSelect(inputValue) {
    setClientEmailInputValue(inputValue);
  }

  function handleEmailInputKeyDown(event) {
    if (!clientEmailInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setClientEmailInputValue("");
        setCreatableEmailSelectValue([
          ...creatableEmailSelectValue,
          createOption(clientEmailInputValue),
        ]);
        setClientEmail([...clientEmail, clientEmailInputValue]);
        event.preventDefault();
    }
  }

  function handleChangeCreatablePhoneSelect(value, actionMeta) {
    setCreatablePhoneSelectValue(value);
    setClientPhone(value.map((value) => value.value));
  }

  function handleInputChangeCreatablePhoneSelect(inputValue) {
    setClientPhoneInputValue(inputValue);
  }

  function handlePhoneInputKeyDown(event) {
    if (!clientPhoneInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setClientPhoneInputValue("");
        setCreatablePhoneSelectValue([
          ...creatablePhoneSelectValue,
          createOption(clientPhoneInputValue),
        ]);
        setClientPhone([...clientPhone, clientPhoneInputValue]);
        event.preventDefault();
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="text-left">
        <div className="row">
          <div className="col-md-6">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_name">Nome</label>
              <input
                type="text"
                name="name"
                id="client_name"
                placeholder="Nome"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_email">E-mails</label>
              <CreatableSelect
                components={{
                  DropdownIndicator: null,
                }}
                inputValue={clientEmailInputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChangeCreatableEmailSelect}
                onInputChange={handleInputChangeCreatableEmailSelect}
                onKeyDown={handleEmailInputKeyDown}
                placeholder="E-mails"
                value={creatableEmailSelectValue}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_name">Telefone</label>
              <CreatableSelect
                components={{
                  DropdownIndicator: null,
                }}
                inputValue={clientPhoneInputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChangeCreatablePhoneSelect}
                onInputChange={handleInputChangeCreatablePhoneSelect}
                onKeyDown={handlePhoneInputKeyDown}
                placeholder="Telefones"
                value={creatablePhoneSelectValue}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_resposible_name">
                Nome do responsável
              </label>
              <input
                type="text"
                name="responsible_name"
                id="client_responsible_name"
                required
                placeholder="Nome do responsável"
                value={clientResponsibleName}
                onChange={(e) => setClientResponsibleName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-6">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_sending_day">Dia de disparo</label>
              <input
                type="number"
                max="28"
                min="1"
                name="sending_day"
                id="client_sending_day"
                placeholder="Insira aqui o intervalo de tempo que você deseja receber o lembrete para enviar novas pesquisas."
                value={clientSendingDay}
                onChange={(e) => setClientSendingDay(e.target.value)}
              />
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="input-block d-flex flex-column">
              <label htmlFor="client_name">Observações</label>
              <textarea
                rows="4"
                cols="50"
                value={clientObservation}
                onChange={(e) => setClientObservation(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="test_client"
              id="test_client"
              checked={clientTest}
              onChange={() => setClientTest(!clientTest)}
            />
            <label className="form-check-label" htmlFor="test_client">
              Usuário para testes
            </label>
            </div>
            
          </div>
          <div className="col-md-8">
            <div className="submit-form pl-5" >
              <button type="submit">Finalizar</button>
            </div>
          </div>
        </div>
      </form>
      {loading && <Spinner />}
    </>
  );
}

export default ClientSimpleForm;
