import React, { useState, useEffect, useId } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

import api from '../../services/api';
import Spinner from '../../components/Spinner';
import PageTop from '../../components/PageTop';
import CustomerProfileEdition from '../../parts/CustomerProfileEdition';
import { getToken } from '../../services/auth';


function CustomersProfileShow() {

    let history = useHistory();
    let location = useLocation();
    const { id: clientId } = useParams();

    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userPasswordConfirmation, setUserPasswordConfirmation] = useState('');
    const [logo, setLogo] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [emailsLimit, setEmailsLimit] = useState('');
    const [loading, setLoading] = useState(false);

    const buttons = [
        {
            text: 'Salvar',
            style: 'green',
            action: saveChanges
        },
        {
            text: 'Apagar cliente',
            style: 'black',
            action: deleteCustomer,
        },
    ];

    useEffect(() => {
        loadCustomer();
    }, []);

    async function loadCustomer() {
        await api.get(`customers/${clientId}`)
        .then(response => {
            setUserId(response.data.user_id);
            setUserName(response.data.user.name);
            setUserEmail(response.data.user.email);
            setLogo('storage/' + response.data.logo);
            setCompanyName(response.data.name);
            setSenderEmail(response.data.sender_email);
            setEmailsLimit(response.data.emails_limit);
        }).catch(error => {
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'erro',
                layout: 'topRight',
                text: 'Erro ao carregar informações do cliente!'
            }).show();
        });
    }

    async function saveChanges(event) {
        setLoading(true);
        const token = getToken();
        const formData = new FormData();

        formData.append('user_id', userId);
        formData.append('logo', logo);
        formData.append('user[name]', userName);
        formData.append('user[email]', userEmail);
        formData.append('user[password]', userPassword);
        formData.append('user[password_confirmation]', userPasswordConfirmation);
        formData.append('name', companyName);
        formData.append('sender_email', senderEmail);
        formData.append('emails_limit', emailsLimit);
        formData.append('_method', "put");

        await api.post(`customers/${clientId}`, formData, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            setLoading(false);
            window.showNotification('success', 'Alterações salvas com sucesso!')
        }).catch(response => {
            setLoading(false);
            window.showNotification('error', 'Houve um erro ao realizar a alteração.')
        });
    }

    async function deleteCustomer() {
        setLoading(true);
        await api.delete(`customers/${clientId}`)
        .then(response => {
            setLoading(false);
            window.showNotification('success', 'Cliente apagado com sucesso!');
            history.replace({
                pathname: `/empresas`,
                state: {}
            })
        })
        .catch(error => {
            setLoading(false);
                window.showNotification('error', 'Erro ao apagar cliente.');
        });
    }

    return (
        <>
            <PageTop title={companyName} 
                comeBackUrl="/empresas"
                comeBack={true}
                buttons={buttons}
            />
            <CustomerProfileEdition 
            userId={userId}
            userName={userName}
            setUserName={setUserName}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            userPassword={userPassword}
            setUserPassword={setUserPassword}
            userPasswordConfirmation={userPasswordConfirmation}
            setUserPasswordConfirmation={setUserPasswordConfirmation}
            logo={logo}
            setLogo={setLogo}
            companyName={companyName}
            setCompanyName={setCompanyName}
            senderEmail={senderEmail}
            setSenderEmail={setSenderEmail}
            emailsLimit={emailsLimit}
            setEmailsLimit={setEmailsLimit}
            />
            
            {loading && <Spinner />}
        </>
    );
}

export default CustomersProfileShow;