import React from 'react';

export default function DashboardItem({ children, title, value, aditionalValue, icon }) {
    return (
        <div className="col-md-4">
            <div className="dashboard-item observations row align-items-center">
                <div className={`${children ? 'col-md-12' : 'col-md-9'}`}>
                    <div className="d-flex align-items-center">
                        <span className="item-title text-uppercase mr-1">{title}</span>
                        {
                            aditionalValue
                            &&
                            <>
                                <i className="material-icons text-dark h6" data-toggle="tooltip" data-placement="top" title={aditionalValue}>info</i>
                            </>
                        }
                    </div>
                    <div>
                        {
                            children
                            ?
                            children
                            :
                            <div>
                                <span className="item-value">{value}</span>
                            </div>
                        }
                    </div>
                </div>
                {
                    icon
                        ?
                        <div className="col-md-3">
                            <div className="item-icon">
                                <i className="material-icons d-flex align-items-center justify-content-center">{icon}</i>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}