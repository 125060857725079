import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import DashboardItem from "../../components/DashboardItem";
import api from "../../services/api";
import PageTop from "../../components/PageTop";
import CustomDataTable from "../../components/CustomDataTable";
import Spinner from "../../components/Spinner";

export default function Dashboard() {
  let history = useHistory();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadReport() {
      setLoading(true);
      api
        .get("dashboard/report")
        .then((response) => {
          // console.log(response.data);
          setReport(response.data);
          if(response.data.report.quantity_sended <= 1){
            Swal.fire(
              "Seja bem-vindo(a) ao CX Solution!",
              " A plataforma que te auxilia a medir o sucesso dos seus clientes e a satisfação deles com a sua empresa e os seus serviços.",
              "success"
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          window.showNotification("error", "Erro ao carregar relatórios.");
        });
    }
    loadReport();
  }, []);

  function returnScoreIconName(score) {
    return score >= 75
      ? "sentiment_very_satisfied"
      : score >= 50 && score <= 74
      ? "sentiment_satisfied"
      : score >= 0 && score <= 49
      ? "sentiment_dissatisfied"
      : "sentiment_very_dissatisfied";
  }

  function rowClicked(rowData) {
    history.replace({
      pathname: `/clientes/${rowData.client_id}`,
      state: { clientName: rowData.client_name, score: rowData.score },
    });
  }

  const dataTableColumns = [
    {
      name: "Cliente",
      selector: "client_name",
      sortable: true,
    },
    {
      name: "Score",
      selector: "score",
      sortable: true,
    },
  ];  
  

  return (
    <>
      <PageTop title={"Dashboard"} navigation={false} />
      {report ? (
        <>
          <div className="row">
            <DashboardItem
              title="NPS"
              icon={returnScoreIconName(report.report.score)}
              value={Math.round(report.report.score)}
            />
            <DashboardItem
              title="Feedback"
              icon="comment"
              value={report.report.observation_quantity}
            />
            <DashboardItem
              title="Disparos"
              icon="email"
              value={`${report.customerReport.emails_count}/${report.customerReport.emails_limit}`}
              aditionalValue={`${report.customerReport.emails_count} disparos de ${report.customerReport.emails_limit} no ciclo atual`}
            />
          </div>
          <div className="row mt-4">
            <DashboardItem title="Detratores">
              <CustomDataTable
                paginationPerPage={5}
                noRowsPerPage={true}
                data={report.detractorsReport}
                columns={dataTableColumns}
                selectableRows={false}
                rowClicked={rowClicked}
              />
            </DashboardItem>
            <DashboardItem title="Neutros">
              <CustomDataTable
                paginationPerPage={5}
                noRowsPerPage={true}
                data={report.neutralReport}
                columns={dataTableColumns}
                selectableRows={false}
                rowClicked={rowClicked}
              />
            </DashboardItem>
            <DashboardItem title="Promotores">
              <CustomDataTable
                paginationPerPage={5}
                noRowsPerPage={true}
                data={report.promotersReport}
                columns={dataTableColumns}
                selectableRows={false}
                rowClicked={rowClicked}
              />
            </DashboardItem>
          </div>
        </>
      ) : null}
      {loading && <Spinner />}
    </>
  );
}
