import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';  

function CustomerProfileEdition({ 
    userId, 
    userName, 
    setUserName, 
    userEmail, 
    setUserEmail, 
    userPassword, 
    setUserPassword, 
    userPasswordConfirmation, 
    setUserPasswordConfirmation, 
    logo, 
    setLogo, 
    companyName, 
    setCompanyName, 
    senderEmail, 
    setSenderEmail,
    emailsLimit,
    setEmailsLimit
}) {
    const [logoPreview, setLogoPreview] = useState('');
    const role = secureLocalStorage.getItem('role');

    useEffect(() => {
        if (!logo) {
            setLogoPreview(undefined)
            return
        }

        if(typeof logo !== 'string') {
            const objectUrl = URL.createObjectURL(logo);
            setLogoPreview(objectUrl);
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl);
        }

    }, [logo]);

    return (
        <form action="#" id="customer-edition-form" autoComplete="off">
            <div className="row">
                <div className="col-md-12">
                    <h4>Usuário</h4>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="user_name">Nome do usuário</label>
                        <input type="text"
                            name="name"
                            id="user_name"
                            placeholder="Nome"
                            required
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="user_email">E-mail do usuário</label>
                        <input type="text"
                            name="email"
                            id="user_email"
                            placeholder="E-mail"
                            required
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="user_password">Senha do usuário</label>
                        <input type="password"
                            name="password"
                            id="user_password"
                            placeholder="Senha"
                            required
                            autoComplete="new-password"
                            value={userPassword}
                            onChange={(e) => setUserPassword(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="user_password_confirm">Confirme a senha do usuário</label>
                        <input type="password"
                            name="password"
                            id="user_password_confirm"
                            placeholder="Confirmação da senha"
                            required
                            autoComplete="off"
                            value={userPasswordConfirmation}
                            onChange={(e) => setUserPasswordConfirmation(e.target.value)} />
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <h4>Empresa</h4>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="input-block d-flex flex-column">
                                <label htmlFor="company_logo">Logo da empresa</label>
                                <input type="file"
                                    name="logo"
                                    id="company_logo"
                                    placeholder="Logo"
                                    onChange={(e) => setLogo(e.target.files[0])} />
                            </div>
                        </div>
                        <div className="col-md-2">
                        {
                            logo 
                        &&
                            <img 
                            src={logoPreview ? logoPreview : `${process.env.REACT_APP_API_URL}/${logo}`} 
                            alt={"Logo " + companyName} 
                            className="w-100"
                            />
                        }
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="company_name">Nome da empresa</label>
                        <input type="text"
                            name="name"
                            id="company_name"
                            placeholder="Nome"
                            required
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-block d-flex flex-column">
                        <label htmlFor="sender_email">Em qual e-mail você deseja receber as respostas das pesquisas?</label>
                        <input type="text"
                            name="email"
                            id="sender_email"
                            placeholder="E-mail"
                            required
                            value={senderEmail}
                            onChange={(e) => setSenderEmail(e.target.value)} />
                    </div>
                </div>
                {
                    role == 'admin'
                    &&
                    <div className="col-md-6">
                        <div className="input-block d-flex flex-column">
                            <label htmlFor="emails_limit">Número de disparos por ciclo</label>
                            <input type="number"
                                name="emails_limit"
                                id="emails_limit"
                                placeholder="Disparos por ciclo"
                                required
                                value={emailsLimit}
                                onChange={(e) => setEmailsLimit(e.target.value)}
                            />
                        </div>
                    </div>
                }
            </div>
        </form>
    );
}

export default CustomerProfileEdition;