import React, { useState, useEffect } from 'react';
import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

import api from '../../services/api';
import { getToken } from '../../services/auth';
import Spinner from '../../components/Spinner';
import PageTop from '../../components/PageTop';
import CustomerProfileEdition from '../../parts/CustomerProfileEdition';
import ConfigurationsEdition from '../../parts/ConfigurationsEdition';

export default function Profile() {
    const [alertDay, setAlertDay] = useState('');
    const [activeTab, setActiveTab] = useState('profile');
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userPasswordConfirmation, setUserPasswordConfirmation] = useState('');
    const [logo, setLogo] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const buttons = [
        {
            text: 'Salvar',
            style: 'green',
            action: saveChanges
        },
    ]

    useEffect(() => {
        loadCustomer();
        loadProfileConfiguration();
    }, []);

    async function loadCustomer() {
        setLoading(true);
        await api.get('customers/profile')
        .then(response => {
            setUserId(response.data.user.id);
            setUserName(response.data.user.name);
            setUserEmail(response.data.user.email);
            setLogo('storage/' + response.data.logo);
            setCompanyName(response.data.name);
            setSenderEmail(response.data.sender_email);
        }).catch(error => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'erro',
                layout: 'topRight',
                text: 'Erro ao carregar informações do cliente!'
            }).show();
        });
    }

    async function loadProfileConfiguration() {
        setLoading(true);
        const token = getToken();

        await api.get('configuration').then(response => {
            if(response.data.configuration) {
                setAlertDay(response.data.configuration.nps_alert_days);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            window.showNotification('error', 'Houve um erro ao carregar a configuração.');
        });
    }

    async function saveChanges(event) {
        setLoading(true);
        const token = getToken();
        const formData = new FormData();

        formData.append('user_id', userId);
        formData.append('logo', logo);
        formData.append('user[name]', userName);
        formData.append('user[email]', userEmail);
        formData.append('user[password]', userPassword);
        formData.append('user[password_confirmation]', userPasswordConfirmation);
        formData.append('name', companyName);
        formData.append('sender_email', senderEmail);
        formData.append('_method', "put");

        function reqProfile() {
            return api.post(`customers/profile`, formData, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            });
        }

        await Promise.all(
            [
                // reqConfig(), 
                reqProfile()
            ]
            ).then(function(responses) {
            setLoading(false);
            window.showNotification('success', 'Alterações salvas com sucesso!')
        }).catch(errors => {
            setLoading(false);
            window.showNotification('error', 'Houve um erro ao realizar a alteração.');
        });
    }

    return(
        <>
            <PageTop 
                title={'Perfil'} 
                buttons={buttons}
            />
                <CustomerProfileEdition 
                    userName={userName}
                    setUserName={setUserName}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    userPassword={userPassword}
                    setUserPassword={setUserPassword}
                    userPasswordConfirmation={userPasswordConfirmation}
                    setUserPasswordConfirmation={setUserPasswordConfirmation}
                    logo={logo}
                    setLogo={setLogo}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    senderEmail={senderEmail}
                    setSenderEmail={setSenderEmail}
                />
            {loading && <Spinner />}
        </>
    );
}