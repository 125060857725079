import React, { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import Noty from "noty";

import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";

import ClientLevel from "../../components/ClientLevel";
import ClientStatus from "../../components/ClientStatus";
import EnterpriseLevel from "../../components/EnterpriseLevel";
import CustomDataTable from "../../components/CustomDataTable";
import FloatButton from "../../components/FloatButton";
import PageTop from "../../components/PageTop";
import ClientSimpleForm from "../../parts/ClientSimpleForm";
import ClientSimpleImport from "../../parts/ClientSimpleImport";
import Sidenav from "../../components/Sidenav";
import Spinner from "../../components/Spinner";

import api from "../../services/api";

function Client() {
  let history = useHistory();
  const [actionSidenav, setActionSidenav] = useState(0);
  const contextActionSidenav = createContext([actionSidenav, setActionSidenav]);
  const [activeTab, setActiveTab] = useState(0);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [generatedLink, setGeneratedLink] = useState("");
  const [showGeneratedLink, setShowGeneratedLink] = useState(false);
  const [enterpriseScore, setEnterpriseScore] = useState(0);
  const [loading, setLoading] = useState(false);

  const [navigationList, setNavigationList] = useState([
    {
      name: "Ativos",
    },
    {
      id: "inativo",
      name: "Inativos",
    }
  ]);

  useEffect(() => {
    setLoading(true);

    async function loadClients() {
      await api
        .get(`client/all/${activeTab}`, {
          params: {
            is_deleted: 0
          }
        })
        .then((response) => {

          setLoading(false);
          setClients([...response.data.data]);
          setEnterpriseScore(response.data.score);
        })
        .catch((error) => {
          setLoading(false);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "error",
            layout: "topRight",
            text: "Ocorreu um erro ao carregar os clientes!",
          }).show();
        });
    }
    loadClients();
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);

    async function loadServices() {
      await api
        .get("service/all")
        .then((response) => {
          setLoading(false);
          // setNavigationList([...navigationList, ...response.data.data]);
        })
        .catch((error) => {
          setLoading(false);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "error",
            layout: "topRight",
            text: "Ocorreu um erro ao carregar os serviços!",
          }).show();
        });
    }

    loadServices();
  }, []);

  const dataTableColumns = [
    {
      name: "Cliente",
      selector: "name",
      sortable: true,
      filterable: true,
    },
    {
      name: "Disparos",
      selector: "quantities_sended",
      sortable: true,
      filterable: true,
      cell: (row) => <span>{row.quantities_sended}</span>,
    },
    {
      name: "Respostas",
      selector: "answers",
      sortable: true,
      filterable: true,
      cell: (row) => <span>{row.answers}</span>,
    },
    {
      name: "Score",
      selector: "score",
      sortable: true,
      filterable: true,
      cell: (row) => <span>{row.score}</span>,
    },
    {
      name: "Nivel",
      selector: "level",
      filterable: true,
      cell: (row) => {
        return <ClientLevel score={row.score} />;
      },
    }
  ];

  function rowClicked(rowData) {
    history.replace({
      pathname: `/clientes/${rowData.id}`,
      state: { clientName: rowData.name, score: rowData.score },
    });
  }

  function sidenavAction() {
    setActionSidenav(!actionSidenav);
  }

  function dataTableSelectedAction(data) {
    setSelectedClients(data.selectedRows);
  }

  async function sendNps(event) {
    setLoading(true);
    if (selectedClients.length > 0) {
      await api
        .post("nps/store", {
          service_id: activeTab,
          clients: selectedClients,
        })
        .then((response) => {
          setLoading(false);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "success",
            layout: "topRight",
            text: "NPS enviado com sucesso!",
          }).show();
        })
        .catch((response) => {
          setLoading(false);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "error",
            layout: "topRight",
            text: "Ocorreu um erro ao enviar o nps!",
          }).show();
        });
    } else {
      setLoading(false);
      new Noty({
        theme: "mint",
        timeout: 10000,
        progressBar: true,
        closeWith: ["click", "button"],
        type: "error",
        layout: "topRight",
        text: "Selecione os clientes!",
      }).show();
    }
  }

  async function generateNpsLink(event) {
    setLoading(true);
    if (selectedClients[0]) {
      await api
        .post(`nps/generate-link`, {
          service_id: activeTab,
          client_id: selectedClients[0].id,
        })
        .then(async (response) => {
          setLoading(false);
          setGeneratedLink(response.data.data);
          setShowGeneratedLink(true);
          await navigator.clipboard.writeText(response.data.data);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "success",
            layout: "topRight",
            text: "Link gerado e copiado!",
          }).show();
        })
        .catch((error) => {
          setLoading(false);
          new Noty({
            theme: "mint",
            timeout: 10000,
            progressBar: true,
            closeWith: ["click", "button"],
            type: "error",
            layout: "topRight",
            text: "Ocorreu um erro ao gerar o link!",
          }).show();
        });

      setTimeout(() => {
        setShowGeneratedLink(false);
        setGeneratedLink("");
      }, 10000);
    } else {
      setLoading(false);
      new Noty({
        theme: "mint",
        timeout: 10000,
        progressBar: true,
        closeWith: ["click", "button"],
        type: "error",
        layout: "topRight",
        text: "Selecione um cliente!",
      }).show();
    }
  }

  const buttons = [
    // {
    //   text: "Gerar Link",
    //   style: "black",
    //   icon: "link",
    //   action: generateNpsLink,
    //   conditional_class: activeTab ? "d-flex" : "d-none",
    // },
    // {
    //   text: "Enviar nps",
    //   style: "black",
    //   icon: "send",
    //   action: sendNps,
    //   conditional_class: activeTab ? "d-flex" : "d-none",
    // },
  ];

  return (
    <>
      <PageTop
        buttons={buttons}
        title={"Clientes"}
        navigation={true}
        navigationItens={navigationList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        complementaryInfo={<EnterpriseLevel score={enterpriseScore} />}
      >
        <FloatButton location={"top"} action={sidenavAction} />
      </PageTop>

      <CustomDataTable
        selectedAction={dataTableSelectedAction}
        data={clients}
        columns={dataTableColumns}
        rowClicked={rowClicked}
      />

      <Sidenav title="Clientes" action={contextActionSidenav}>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              id="create-tab"
              className="nav-link active"
              data-toggle="tab"
              role="tab"
              aria-controls="client-form"
              href="#client-form"
            >
              Adicionar Cliente
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              id="import-tab"
              className="nav-link"
              data-toggle="tab"
              role="tab"
              aria-controls="client-import"
              href="#client-import"
            >
              Importar lista de clientes
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="client-form"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="create-tab"
          >
            <ClientSimpleForm />
          </div>
          <div
            id="client-import"
            className="tab-pane fade"
            role="tabpanel"
            aria-labelledby="import-tab"
          >
            <ClientSimpleImport />
          </div>
        </div>
      </Sidenav>

      {/* <ClientSimpleForm action={contextActionSidenav} /> */}

      {showGeneratedLink ? (
        <div id="bottom-noty">
          <span id="generated-link">{generatedLink}</span>
        </div>
      ) : null}
      {loading && <Spinner />}
    </>
  );
}

export default Client;
