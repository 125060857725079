import React from 'react';
import secureLocalStorage from 'react-secure-storage';
import { NavLink, useHistory } from 'react-router-dom';

import { logout } from '../../services/auth';

import './main.css'

function Menu() {

    const history = useHistory();
    const role = secureLocalStorage.getItem('role');

    function handleLogout() {
        logout();
        history.push('/');
    }

    return (
        <nav id="menu-nav" className='bg-cx-gray'>
            <div className="logo-container text-center">
                <img src={process.env.REACT_APP_COMPANY_ICON ? process.env.REACT_APP_COMPANY_ICON : "/logo.png"} alt="Verticis" width="100px"/>
            </div>
            <div className="menu-container">
                <div className="menu-item">
                    <NavLink exact to="/" activeclassname="menu-item-active">
                        <span className="material-icons">dashboard</span>
                        Dashboard
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/clientes" activeclassname="menu-item-active">
                        <span className="material-icons">person</span>
                        Clientes
                    </NavLink>
                </div>
                {/* <div className="menu-item">
                    <NavLink to="/servicos" activeClassName="menu-item-active">
                        <span className="material-icons">work</span>
                        Serviços
                    </NavLink>
                </div> */}
                {
                    role == 'admin' 
                    &&
                    <div className="menu-item">
                        <NavLink to="/empresas" activeclassname="menu-item-active">
                            <span className="material-icons">business</span>
                            Empresas
                        </NavLink>
                    </div> 
                }
                <div className="menu-item">
                    <NavLink to="/perfil" activeclassname="menu-item-active">
                        <span className="material-icons">account_circle</span>
                        Perfil
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/pesquisas" activeclassname="menu-item-active">
                        <span className="material-icons">poll</span>
                        Pesquisas
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink to="/lixeira" activeclassname="menu-item-active">
                        <span className="material-icons">delete</span>
                        Lixeira
                    </NavLink>
                </div>
                <div className="menu-item">
                    <a onClick={handleLogout} role='button' activeclassname="menu-item-active">
                        <span className="material-icons">logout</span>
                        Sair
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Menu;