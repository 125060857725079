import axios from 'axios';

import { getToken, logout } from './auth';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1/`
});

api.interceptors.request.use(config => {
    const token = getToken();
    // window.showLoading();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    window.showNotification('error', 'Houve um erro ao realizar a sua requisição!');
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401) {
        window.location.replace('/login');
        window.showNotification('error', 'Faça login novamente para continuar.');
        logout();
    }
    window.showNotification('error', 'Houve um erro ao finalizar a sua requisição!');
    return Promise.reject(error);
});

export default api;