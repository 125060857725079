import React, { useState, useEffect, useCallback } from "react";
import Select, { createFilter } from "react-select";
import Select2 from "react-select2-wrapper";
import $ from "jquery";

import "./styles.css";
import "react-select2-wrapper/css/select2.css";

export default function PollSimpleForm(props) {
  const {
    clients,
    message,
    setMessage,
    mainQuestion,
    setMainQuestion,
    questions,
    setQuestions,
    clientEmails,
    setClientEmails,
  } = props;

  const [removedQuestions, setRemovedQuestions] = useState([]);

  function addQuestion() {
    setQuestions([...questions, { content: "" }]);
  }

  function removeQuestion(id, key) {
    if (id) {
      setRemovedQuestions([...removedQuestions, { id }]);
    }
    setQuestions(
      questions.filter((value, index) => key !== index || value.id !== id)
    );
  }

  const handleEmailSelectChange = async (e) => {
    setClientEmails({});
    var clientsIds = [];
    var clientsEmailsObj = {};
    await e.map(client => {
      if(!clientsIds.includes(client.clientId)) {
        clientsIds.push(client.clientId)
      }
    });

    clientsIds.map(id => {
      var emails = [];
      e.filter(client => client.clientId === id).map(client => {
        emails.push(client.value);
      });
      clientsEmailsObj = {...clientsEmailsObj, [id]: emails};
    });
    setClientEmails(clientsEmailsObj);
  };

  const filterOption = ({ label, value }, string) => {
    string = string.toLocaleLowerCase();

    if (label?.includes(string) || value?.includes(string)) return true;

    const groupOptions = clients.filter((group) =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <form id="poll-form">
      <div className="row">
        <div className="col-md-12">
          <div className="input-block d-flex flex-column">
            <small className="text-danger">
              1 - Selecione o contato do seu cliente
            </small>
            <label htmlFor="clients" className="text-dark font-weight-bold">
              Clientes
            </label>
            <Select
              id="clients"
              placeholder="Selecione um ou mais e-mails"
              isMulti
              options={clients}
              className="basic-multi-select"
              classNamePrefix="select"
              filterOption={filterOption}
              onChange={(e) => handleEmailSelectChange(e)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <div className="input-block d-flex flex-column">
            <small className="text-danger">
              2 - Insira o texto no campo da Mensagem convidando o seu cliente a
              responder a pesquisa
            </small>
            <label htmlFor="message" className="text-dark font-weight-bold">
              Mensagem
            </label>
            <textarea
              name="message"
              placeholder="Sugestão: para aprimorar a qualidade dos nossos serviços, gostaríamos de saber como foi a sua experiência conosco."
              id="message"
              value={message}
              rows="2"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="input-block d-flex flex-column">
            <small className="text-danger">
              3 - Preencha o campo da pergunta principal
            </small>
            <label
              htmlFor="main_question"
              className="text-dark font-weight-bold"
            >
              Pergunta principal
            </label>
            <span className="pb-3">
              <span className="fs-15">
                A pergunta principal do NPS tem o intuito de avaliar o grau de
                satisfação do seu cliente, de maneira geral ou pontual.
              </span>
            </span>
            <textarea
              name="main_question"
              id="main_question"
              placeholder="Sugestão: “De 0 a 10, quanto você recomendaria a nossa empresa para um amigo ou familiar?”"
              value={mainQuestion}
              rows="2"
              onChange={(e) => setMainQuestion(e.target.value)}
            />
          </div>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-md-12">
          <div className="input-block mb-4 d-flex flex-column ">
            <small className="text-danger">
              4 - Por último, preencha os campos das questões
            </small>
            <label className="font-weight-bold text-dark">Questões</label>
            <span className="fs-15">
              As questões são avaliações mais específicas e têm como finalidade
              receber um feedback sobre os seus processos e serviços para que
              assim você possa aprimorá-los.
              <br />
              Você pode adicionar quantas questões desejar e o seu cliente
              avaliará com a métrica do Like e Dislike (Gostei/Não Gostei).
            </span>
            {questions.map((question, key) => (
              <div className="row" key={key}>
                <div className="col-md-12 d-flex flex-row mb-3">
                  <input
                    type="text"
                    className="flex-1"
                    placeholder="Sugestão: “Qualidade do atendimento”, “Cumprimento de prazos”, “Qualidade do produto”, “Relatórios”, “Suporte ao cliente”"
                    value={question.content}
                    onChange={(e) =>
                      setQuestions(
                        questions.filter((q, index) => {
                          if (key === index) {
                            q.content = e.target.value;
                          }
                          return q;
                        })
                      )
                    }
                  />
                  <button
                    className="custom-btn d-flex align-items-center justify-content-center align-self-start ml-2"
                    type="button"
                    onClick={() => removeQuestion(question.id, key)}
                  >
                    <span className="material-icons delete-data">delete</span>
                  </button>
                </div>
              </div>
            ))}
            {
              <button
                className="add-item-repeater custom-btn btn-cx d-flex align-items-center align-self-start"
                type="button"
                onClick={() => addQuestion()}
              >
                ADD <i className="material-icons">add</i>
              </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
}
