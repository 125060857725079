import React, { useState, useMemo, memo } from 'react';

import DataTable from 'react-data-table-component';

const conditionalRowStyles = [
    {
      when: row => row.test_client == 1,
      style: {
        backgroundColor: '#515151',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

function CustomDataTable({ columns, data, rowClicked, selectedAction, paginationPerPage = 100, noRowsPerPage = false, selectableRows = true }) {
    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = () => {
        let filteredColumns = columns.filter(column => column.filterable && column.filterable == true)
        return filteredColumns.length
            ?
            data.filter(
                item => filteredColumns.some(column => {
                    if (item[column.selector] && item[column.selector].toString().toLowerCase().includes(filterText.toLowerCase())) {
                        return true;
                    }
                })
            )
            :
            data

    }

    const paginationConfig = { rowsPerPageText: 'Linhas por página:', rangeSeparatorText: 'de', noRowsPerPage, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }
    const paginationRowsPerPageOptions = [25, 50, 75, 100];

    const NoDataComponent = () => <div style={{ padding: '24px' }}>Nenhum dado encontrado!</div>

    const subHeaderComponentMemo = useMemo(() => {

        let filteredColumns = columns.filter(column => column.filterable && column.filterable == true)
        return (
            filteredColumns.length
                ?
                <input
                    id="search"
                    type="text"
                    placeholder="Filtrar"
                    aria-label="Search Input"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                :
                null

        );
    }, [filterText, resetPaginationToggle]);

    return (
        <div>
            <DataTable pagination columns={columns}
                persistTableHead
                paginationPerPage={paginationPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                data={filteredItems()}
                // selectableRows={selectableRows}
                paginationComponentOptions={paginationConfig}
                noDataComponent={<NoDataComponent />}
                highlightOnHover
                pointerOnHover
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                onSelectedRowsChange={selectedAction}
                onRowClicked={rowClicked}
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
}

export default memo(CustomDataTable);