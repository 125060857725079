import React from 'react';


function ClientStatus({ service_status }) {
    let clientStatus;
    if (service_status !== '-' && service_status !== null) {
        clientStatus = service_status == 0 ? 'Inativo' : 'Ativo';
    }

    return <span className={`client-status ${clientStatus ?? 'empty'}-client-status`}>
        {clientStatus ?? 'Não avaliado'}
        </span>;
}

export default ClientStatus;