import React, {useState, useEffect, createContext} from 'react';
import {useHistory} from "react-router-dom";
import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

import ClientLevel from '../../components/ClientLevel';
import ClientStatus from '../../components/ClientStatus';
import EnterpriseLevel from '../../components/EnterpriseLevel';
import CustomDataTable from '../../components/CustomDataTable';
import FloatButton from '../../components/FloatButton';
import PageTop from '../../components/PageTop';
import ClientSimpleForm from '../../parts/ClientSimpleForm';
import ClientSimpleImport from '../../parts/ClientSimpleImport';
import Sidenav from '../../components/Sidenav';
import Spinner from '../../components/Spinner';

import api from '../../services/api';

function Client() {
    let history = useHistory();
    const [actionSidenav, setActionSidenav] = useState(0);
    const contextActionSidenav = createContext([actionSidenav, setActionSidenav]);
    const [activeTab, setActiveTab] = useState(0);
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [generatedLink, setGeneratedLink] = useState('');
    const [showGeneratedLink, setShowGeneratedLink] = useState(false);
    const [enterpriseScore, setEnterpriseScore] = useState(0);
    const [loading, setLoading] = useState(false);

    const [navigationList, setNavigationList] = useState([
        {
            name: 'Todos os clientes',
        }
    ]);

    useEffect(() => {
        setLoading(true);

        async function loadClients() {
            await api.get(`client/all/${activeTab}`, {
                params: {
                    is_deleted: 1
                }
            })
            .then(response => {
                setLoading(false);
                setClients([...response.data.data]);
                setEnterpriseScore(response.data.score);
            })
            .catch(error => {
                setLoading(false);
                new Noty({
                    theme: 'mint',
                    timeout: 10000,
                    progressBar: true,
                    closeWith: ['click', 'button'],
                    type: 'error',
                    layout: 'topRight',
                    text: 'Ocorreu um erro ao carregar os clientes!'
                }).show();
            });
        }
        loadClients();
    }, [activeTab])

    useEffect(() => {
        setLoading(true);

        async function loadServices() {
            await api.get('service/all')
            .then(response => {
                setLoading(false);
                // setNavigationList([...navigationList, ...response.data.data]);
            })
            .catch(error => {
                setLoading(false);
                new Noty({
                    theme: 'mint',
                    timeout: 10000,
                    progressBar: true,
                    closeWith: ['click', 'button'],
                    type: 'error',
                    layout: 'topRight',
                    text: 'Ocorreu um erro ao carregar os serviços!'
                }).show();
            })
        }

        loadServices();
    }, [])

    const dataTableColumns = [
        {
            name: 'Cliente',
            selector: 'name',
            sortable: true,
            filterable: true
        },
        {
            name: 'Disparos',
            selector: 'quantities_sended',
            sortable: true,
            filterable: true,
            cell: row => <span>{row.quantities_sended}</span>
        },
        {
            name: 'Respostas',
            selector: 'answers',
            sortable: true,
            filterable: true,
            cell: row => <span>{row.answers}</span>
        },
        {
            name: 'Score',
            selector: 'score',
            sortable: true,
            filterable: true,
            cell: row => <span>{row.score}</span>
        },
        {
            name: 'Nivel',
            selector: 'level',
            filterable: true,
            cell: row => {
                return <ClientLevel score={row.score}/>
            }
        },
        {
            name: 'Pesquisas',
            selector: 'service_status',
            sortable: true,
            filterable: true,
            cell: row => <ClientStatus service_status={row.service_status}/>

        }
    ];

    function rowClicked(rowData) {
        history.replace({
            pathname: `/clientes/${rowData.id}`,
            state: {clientName: rowData.name, score: rowData.score}
        })
    }

    function dataTableSelectedAction(data) {
        setSelectedClients(data.selectedRows);
    }

    return (
        <>
            <PageTop
                title={'Clientes excluídos'} 
                navigation={true} 
                navigationItens={navigationList}
                activeTab={activeTab} setActiveTab={setActiveTab}
                complementaryInfo={<EnterpriseLevel score={enterpriseScore}/>}
            >
                </PageTop>
            <CustomDataTable 
                selectedAction={dataTableSelectedAction} 
                data={clients} columns={dataTableColumns}
                rowClicked={rowClicked}
            />
            {loading && <Spinner/>}
        </>
    );
}

export default Client;