import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PageTop from '../../components/PageTop';
import ServiceEdition from '../../parts/ServiceEdition'

import Spinner from '../../components/Spinner';

import api from '../../services/api';

import Noty from 'noty'

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

function ServiceShow() {
    let location = useLocation();
    const { id: serviceId } = useParams();
    const [loading, setLoading] = useState(false);

    const [serviceName, setServiceName] = useState(location.state ? location.state.serviceName : '');
    const [associatedQuestions, setAssociatedQuestions] = useState([])
    const [allQuestions, setAllQuestions] = useState([])
    const [removedQuestions, setRemovedQuestions] = useState([])

    const [activeServices, setActiveServices] = useState([])
    const [activeTab, setActiveTab] = useState(0);
    const [navigationList, setNavigationList] = useState([
        {
            name: 'Informações'
        }
    ]);

    useEffect(() => {
        async function loadData() {
            setLoading(true);
            await api.get(`service/${serviceId}`)
            .then(response => {
                setServiceName(response.data.service.name)
                setAssociatedQuestions(response.data.associatedQuestions);
                setAllQuestions(response.data.allQuestions);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                window.showNotification('error', 'Erro ao carregar dados.');
            });
        }

        loadData();
    }, [])

    function selectActions(data, action) {
        if (action.removedValue) {
            setActiveServices(activeServices.filter(activeService => {
                if (activeService.id === action.removedValue.service_id) {
                    activeService.selected = activeService.selected.filter(questionSelected => questionSelected.id !== action.removedValue.id)
                }
                return activeService;
            }))
        } else if (action.action === 'select-option') {
            setActiveServices(activeServices.filter(activeService => {
                if (activeService.id === action.option.service_id) {
                    activeService.selected = [...activeService.selected, action.option];
                }
                return activeService;
            }))
        }
    }

    async function saveChanges(event) {
        setLoading(true);
        await api.put(`service/update/${serviceId}`, {
            name: serviceName,
            associatedQuestions,
            removedQuestions
        }).then(response => {
            setLoading(false);
            window.showNotification('success', 'Alterações salvas com sucesso!');
        }).catch(response => {
            setLoading(false);
            new Noty({
                theme: 'mint',
                timeout: 10000,
                progressBar: true,
                closeWith: ['click', 'button'],
                type: 'error',
                layout: 'topRight',
                text: 'Houve um erro ao realizar a alteração'
            }).show();
        })
    }

    const buttons = [
        {
            text: 'Salvar',
            style: 'green',
            action: saveChanges
        }
    ]

    return (
        <>
            <PageTop title={serviceName} navigation={false}
                navigationItens={navigationList}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                comeBackUrl="/servicos"
                comeBack={true}
                buttons={buttons}
            />
            <div className="content row pb-5 px-20">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-content">
                                <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                    <ServiceEdition
                                        associatedQuestions={associatedQuestions}
                                        setAssociatedQuestions={setAssociatedQuestions}
                                        setRemovedQuestions={setRemovedQuestions}
                                        removedQuestions={removedQuestions}
                                        allQuestions={allQuestions}
                                        serviceName={serviceName}
                                        setServiceName={setServiceName}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Spinner />}
        </>
    );
}

export default ServiceShow;